<template>
  <div class="content-box">
    <div class="top">
      <div class="leftdiv" @click="goBack">
        <Icon
          type="ios-arrow-back"
          size="20"
          :color="$store.state.darkTheme ? '#fff' : '#000'"
        />
      </div>
      <!-- <div class="leftdiv"
           style="left:60px"
           @click="shareModel = true">
        <Icon type="md-share"
              color='#fff'
              size='20' />
      </div> -->
      <!-- <div class="leftdiv"
           style="left:120px"
           @click="backCenter">
        <Icon type="md-home"
              color='#fff'
              size='20' />
      </div> -->
      <div style="float: right" @click="onMark">
        <Icon type="md-menu" />
      </div>
      <Button
        :type="mapType == 'roller' ? '' : 'default'"
        style="float: right; margin: 0.1rem"
        @click="rollerChange"
        >卷帘对比</Button
      >
      <Button
        :type="mapType == 'separate' ? '' : 'default'"
        style="float: right; margin: 0.1rem"
        @click="separateChange"
        >分屏对比</Button
      >
      <Button
        type="default"
        style="float: right; margin: 0.1rem"
        @click="backCenter"
        >主视角</Button
      >
    </div>
    <div class="Content" @contextmenu.prevent="rightClick()">
      <div class="inputTop">
        <span>{{ mapname }}</span>
        <!-- <input type="text"
               v-model="mapname"
               @blur="testName(mapname)"
               placeholder="自定义命名区域"> -->
      </div>
      <div class="inputBottom">
        <input
          type="text"
          :style="{
            backgroundColor: $store.state.darkTheme ? '#0c0c0c' : '#f3f3f3',
          }"
          placeholder="自定义信息"
        />
        <input
          type="text"
          :style="{
            backgroundColor: $store.state.darkTheme ? '#0c0c0c' : '#f3f3f3',
          }"
          :placeholder="new Date().toLocaleString()"
        />
      </div>
      <div
        class="left"
        :style="{ visibility: mapType == '' ? 'hidden' : 'visible' }"
        :class="`${
          mapType == 'roller'
            ? 'roller-content'
            : mapType == 'separate'
            ? 'separate-content'
            : ''
        }`"
      >
        <div v-show="mapType != 'roller'" class="boxtop">
          <!-- <Dropdown trigger="click"
                    placement="bottom-end"
                    @on-click='changeMap'>
            <a href="javascript:void(0)">
              更换
            </a>
            <DropdownMenu slot="list">
              <DropdownItem :name='item.id'
                            v-for="(item,i) in mapList"
                            :key="i">{{item.mapname}}</DropdownItem>
            </DropdownMenu>
          </Dropdown> -->
        </div>
        <div
          class="box"
          :class="`${
            mapType == 'roller'
              ? 'roller-box'
              : mapType == 'separate'
              ? 'separate-box'
              : ''
          }`"
        >
          <div class="map previous" id="previous"></div>
          <!-- <h2 style="left: 15px;">卫星图</h2> -->
          <!--高德操作图标-->
          <div class="mapControl">
            <div class="item">
              <img
                src="@/assets/img/Icon/hierarchy.png"
                @click="hierarchyShou2 = !hierarchyShou2"
              />
              <div class="hierarchyBox" v-if="hierarchyShou2">
                <div class="clearfix">
                  <label>
                    <input type="radio" v-model="mapStyle1" value="平面" />
                    <span>平面</span>
                  </label>
                </div>
                <div class="clearfix">
                  <label>
                    <input type="radio" v-model="mapStyle1" value="卫星" />
                    <span>卫星</span>
                  </label>
                </div>
                <hr />

                <div class="clearfix">
                  <label>
                    <input type="checkbox" value="地名" v-model="mapS1" />
                    <span>地名</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="item" @click="zoomIn(1)">
              <img src="@/assets/img/Icon/jia.png" alt />
            </div>
            <div class="item" @click="zoomOut(1)">
              <img src="@/assets/img/Icon/jian.png" alt />
            </div>
          </div>
        </div>
        <div v-show="mapType == 'roller'" @mousedown="moveStart" class="roller">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div
        class="right"
        :class="`${
          mapType == 'roller'
            ? 'roller-ground'
            : mapType == 'separate'
            ? 'separate-ground'
            : ''
        }`"
      >
        <!-- <div v-show="mapType!='roller'"
             class="boxtop"></div> -->
        <div class="box">
          <div class="map upToDate" id="upToDate"></div>
          <!-- <h3>正射图</h3> -->
          <!--高德操作图标-->
          <div class="mapControl">
            <div class="item">
              <img
                src="@/assets/img/Icon/hierarchy.png"
                @click="hierarchyShou1 = !hierarchyShou1"
              />
              <div class="hierarchyBox" v-if="hierarchyShou1">
                <div class="clearfix">
                  <label>
                    <input type="radio" v-model="mapStyle2" value="平面" />
                    <span>平面</span>
                  </label>
                </div>
                <div class="clearfix">
                  <label>
                    <input
                      type="radio"
                      v-model="mapStyle2"
                      value="卫星"
                      checked
                    />
                    <span>卫星</span>
                  </label>
                </div>
                <hr />

                <div class="clearfix">
                  <label>
                    <input type="checkbox" value="地名" v-model="mapS2" />
                    <span>地名</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="item" @click="zoomIn(2)">
              <img src="@/assets/img/Icon/jia.png" alt />
            </div>
            <div class="item" @click="zoomOut(2)">
              <img src="@/assets/img/Icon/jian.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="tagging" v-if="drawing">
        <ul>
          <p style="padding: 10px">工具</p>
          <!-- <li>
            <Button type="primary"
                    v-show="!drawing"
                    @click="onMark">启用标注</Button>
            <Button type="warning"
                    @click="offMark"
                    v-show="drawing"
                    :disabled="!drawed">取消标注</Button>
          </li> -->
          <li class="bzd">
            <img
              @click="draw(1)"
              :class="{ active: 1 == current }"
              :src="Icon.dbz"
              alt=""
            />
            <img
              @click="draw(2)"
              :class="{ active: 2 == current }"
              :src="Icon.xbz"
              alt=""
            />
            <img
              @click="draw(3)"
              :class="{ active: 3 == current }"
              :src="Icon.mbz"
              alt=""
            />
            <img
              @click="draw(4)"
              :class="{ active: 4 == current }"
              :src="Icon.tbz"
              alt=""
            />
            <span>点标注</span><span>线标注</span><span>面标注</span
            ><span>图标</span>
          </li>
          <p style="padding: 10px">图层</p>
          <div class="tagdiv">
            <li
              class="tagdel"
              v-for="(item, i) in overlays.spot"
              :class="{ active2: tagId == item.i }"
              :key="i + 'a'"
            >
              <div style="float: left; width: 15%">
                <Icon
                  type="ios-eye"
                  @click="showTag(item.i, $event)"
                  size="20"
                />
              </div>
              <div class="center">
                <div>{{ item.name }}</div>
                <div>点标注</div>
              </div>
              <div style="float: right" @click="tagDelete(item.i)">
                <Icon type="md-trash" size="20" />
              </div>
            </li>
            <li
              class="tagdel"
              v-for="(item, i) in overlays.line"
              :key="i + 'b'"
              :class="{ active2: tagId == item.i }"
            >
              <div style="float: left; width: 15%">
                <Icon
                  type="ios-eye"
                  @click="showTag(item.i, $event)"
                  size="20"
                />
              </div>
              <div class="center">
                <div>{{ item.name }}</div>
                <div>线标注</div>
              </div>
              <div style="float: right" @click="tagDelete(item.i)">
                <Icon type="md-trash" size="20" />
              </div>
            </li>
            <li
              class="tagdel"
              v-for="(item, i) in overlays.surface"
              :class="{ active2: tagId == item.i }"
              :key="i + 'c'"
            >
              <div style="float: left; width: 15%">
                <Icon
                  type="ios-eye"
                  @click="showTag(item.i, $event)"
                  size="20"
                />
              </div>
              <div class="center">
                <div>{{ item.name }}</div>
                <div>面标注</div>
              </div>

              <div style="float: right" @click="tagDelete(item.i)">
                <Icon type="md-trash" size="20" />
              </div>
            </li>
            <li
              class="tagdel"
              v-for="(item, i) in overlays.icon"
              :class="{ active2: tagId == item.i }"
              :key="i + 'd'"
            >
              <div style="float: left; width: 15%">
                <Icon
                  type="ios-eye"
                  @click="showTag(item.i, $event)"
                  size="20"
                />
              </div>
              <div class="center">
                <div>{{ item.name }}</div>
                <div>图标</div>
              </div>
              <div style="float: right" @click="tagDelete(item.i)">
                <Icon type="md-trash" size="20" />
              </div>
            </li>
          </div>

          <!-- <li>
            <Button type="error"
                    @click="clearMark"
                    :disabled="!drawed">{{
              del == true ? "取消删除" : "删除标注"
            }}</Button>
          </li>
          <li></li>
          <p style="margin-top: 12px">测量</p>
          <li @click="rule('rule')">测距</li>
          <li @click="rule('measureArea')">测面积</li>
          <li>
            <Button type="warning"
                    @click="clearRule">清除测量</Button>
          </li> -->
          <li
            style="border: none; position: absolute; bottom: 60px; width: 228px"
          >
            <Button type="success" long @click="save" :disabled="!drawed"
              >保存标注</Button
            >
          </li>
        </ul>
      </div>
      <div v-else style="width: 50px"></div>
      <!-- 图标 -->
      <div class="taggingMore" v-if="tagType == 4">
        <li><span>图标</span></li>
        <li>
          <span>经度:{{ nowObj.getPosition().lat.toFixed(3) }}</span
          ><br />
          <span>纬度:{{ nowObj.getPosition().lng.toFixed(3) }}</span>
        </li>
        <li>
          <span>标注信息</span>
          <input v-model="tagName" type="text" />
        </li>
        <!-- <li v-if='tagId==""'>
          <span>图标</span>
          <div class="myicon">
            <img v-for="(item,i) in myicon"
                 :key="i+'d'"
                 @click="cusimg(item.img)"
                 :class="{ active: item.img == onImg }"
                 :src="item.img"
                 alt="">

          </div>
        </li> -->
        <li>
          <Button style="margin-right: 20px" @click="saveDraw" type="primary"
            >确定</Button
          >
          <Button @click="cancelDraw">关闭</Button>
        </li>
      </div>
      <div class="taggingMore" v-if="tagType == 3">
        <li><span>面标注</span></li>
        <!-- <li><span>面积:{{nowObj.getArea()}}平方米</span></li> -->
        <li>
          <span>标注信息</span>
          <input v-model="tagName" type="text" />
        </li>
        <li v-if="tagId == ''">
          <span>样式</span><br />
          <span>颜色:</span>
          <div class="color">
            <colorPicker
              v-on:change="headleChangeColor(1)"
              v-model="mycolor.mcolor"
            />
          </div>
        </li>
        <li>
          <Button style="margin-right: 20px" @click="saveDraw" type="primary"
            >确定</Button
          >
          <Button @click="cancelDraw">关闭</Button>
        </li>
      </div>
      <div class="taggingMore" v-if="tagType == 2">
        <li><span>线标注</span></li>
        <!-- <li><span>总长:{{nowObj.getLength()}}米</span></li> -->
        <li>
          <span>标注信息</span>
          <input v-model="tagName" type="text" />
        </li>
        <li v-if="tagId == ''">
          <span>样式</span><br />
          <span>颜色:</span>
          <div class="color">
            <colorPicker
              v-on:change="headleChangeColor(2)"
              v-model="mycolor.xcolor"
            />
          </div>
        </li>
        <li>
          <Button style="margin-right: 20px" @click="saveDraw" type="primary"
            >确定</Button
          >
          <Button @click="cancelDraw">关闭</Button>
        </li>
      </div>
      <div class="taggingMore" v-if="tagType == 1">
        <li><span>点标注</span></li>
        <li>
          <span>经度:{{ nowObj.getPosition().lat }}</span
          ><br />
          <span>纬度:{{ nowObj.getPosition().lng }}</span>
        </li>
        <li>
          <span>标注信息</span>
          <input v-model="tagName" type="text" />
        </li>

        <li>
          <Button style="margin-right: 20px" @click="saveDraw" type="primary"
            >确定</Button
          >
          <Button @click="cancelDraw">关闭</Button>
        </li>
      </div>
    </div>
    <div class="text"></div>
    <!-- <Modal v-model="delModal"
           @on-ok="okDel">
      <p>确认删除该标注</p>
    </Modal> -->
    <!-- <Modal v-model="shareModel"
           width=450
           title="分享链接">
      <div class="shareUrl">
        <p style="margin-bottom:20px">
          <span class="share-span"
                style="width:400px">链接：{{ shareUrl }}</span>
          <Icon type="ios-copy"
                title="点击复制"
                style="cursor: pointer;"
                size=20
                @click="copy" />
        </p>
        <vue-qr :text="shareUrl"
                colorDark="#000"
                colorLight="#fff"
                :margin='5'
                style="vertical-align: bottom;margin-right:20px"
                :size="120"></vue-qr>
        <span class="share-span"
              @click="bigqr=true"
              style="cursor: pointer;">点击放大</span>
      </div>
    </Modal>
    <div class="qr"
         v-show="bigqr">
      <Icon type="md-close-circle"
            @click="bigqr=false"
            color="#fff"
            size=40 />
      <vue-qr :text="shareUrl"
              colorDark="#000"
              colorLight="#fff"
              style="display:block"
              :size="400"></vue-qr>
    </div> -->
  </div>
</template>

<script>
// import goBack from '@/assets/img/Icon/goBack.png';

import Api from "@/utils/api.js";
import { MapDetail } from "@/utils/javaApi.js";
// import vueQr from 'vue-qr';
import dbz from "@/assets/img/statistics/zst_ (1).png";
import xbz from "@/assets/img/statistics/zst_ (2).png";
import mbz from "@/assets/img/statistics/zst_ (3).png";
import tbz from "@/assets/img/statistics/zst_ (4).png";
export default {
  //   components: { vueQr },

  data() {
    return {
      mycolor: {
        mcolor: "#5cadff",
        xcolor: "#5cadff",
      },
      tagName: "",
      tagId: "",
      nowObj: {},
      tagType: 0,
      map_2: null,
      // icon: null,
      edit1: false,
      edit2: false,
      mouseTool: null,
      // ruleTool: null,
      drawing: false,
      overlays: {
        spot: [], // 点
        line: [], // 线
        surface: [], //  面
        icon: [], //图
      }, // 鼠标绘制物
      Icon: {
        dbz: dbz,
        xbz: xbz,
        mbz: mbz,
        tbz: tbz,
      },
      markID: "",
      // del: false,
      delModal: false,
      Distance: "",
      current: 0,
      drawed: true,
      // ruled: true,
      mapType: "",

      //   goBack,
      googleLayer: "",
      // satelliteLayer: '',
      layer: "",
      layer2: "",
      kmlId: "",
      // center: '',

      //   shareModel: false,
      //   shareUrl: '',
      //   sharebaseUrl: 'http://129.204.9.15:9034/orthoimage.html?id=',
      //   bigqr: false,
      mapname: "",
      myMap2: {},
      hierarchyShou1: false,
      hierarchyShou2: false,
      mapStyle1: "卫星",
      mapStyle2: "卫星",
      mapS1: [],
      mapS2: [],

      //图标假数据
      myicon: [
        {
          img: "https://img0.baidu.com/it/u=2488977291,712426206&fm=26&fmt=auto",
          id: "1",
        },
        {
          img: "https://img2.baidu.com/it/u=2765865864,2015928711&fm=26&fmt=auto",
          id: "2",
        },
        {
          img: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fd.lanrentuku.com%2Fdown%2Fpng%2F0904%2FM-v-Player%2FM-v-Player_13.png&refer=http%3A%2F%2Fd.lanrentuku.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638081601&t=30a7310cd20ecc40612a1004a3aa5f5c",
          id: "3",
        },
      ],
      onImg: "",
      myMove: "",
    };
  },
  mounted() {
    // this.kmlId = this.$route.query.id;
    let routerParams = JSON.parse(sessionStorage.getItem("contrast"));
    // this.toLoadMapImg_2(routerParams);
    this.id = routerParams.id;

    // this.$post(MapDetail(), {
    //   kml_id: this.kmlId,
    //   shooting_date: '',
    // }).then((res) => {
    //   if (res.code == 1) {
    //     this.myMap = res.data;
    //     this.myMap2 = res.data;
    this.init_map_1();
    this.init_map_2();
    this.init_map_3();
    this.toLoadMapImg(routerParams);
    //   }
    // });
  },
  watch: {
    mapStyle1(val) {
      //卫星平面切换
      if (val == "平面") {
        this.map_2.remove(this.layer2);
      } else if (val == "卫星") {
        this.map_2.add(this.layer2);
      }
    },
    mapS1(val) {
      //显示默认标注

      if (val.indexOf("地名") !== -1) {
        let features = ["bg", "road", "building", "point"];
        this.map_2.setFeatures(features);
      } else {
        let features = ["bg", "road", "building"];
        this.map_2.setFeatures(features);
      }
    },
    mapStyle2(val) {
      //卫星平面切换
      if (val == "平面") {
        this.map_2_upToDate.remove(this.layer);
      } else if (val == "卫星") {
        this.map_2_upToDate.add(this.layer);
      }
    },
    mapS2(val) {
      //显示默认标注

      if (val.indexOf("地名") !== -1) {
        let features = ["bg", "road", "building", "point"];
        this.map_2_upToDate.setFeatures(features);
      } else {
        let features = ["bg", "road", "building"];
        this.map_2_upToDate.setFeatures(features);
      }
    },
  },
  methods: {
    // 创建地图
    init_map_1() {
      let layerT = [];
      let centerPoint = this.$Center;

      if (this.$online) {
        this.layer2 = new AMap.TileLayer.Satellite();
        layerT = new AMap.TileLayer();
      } else {
        this.layer2 = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeSatelliteMap() + z + "/" + x + "/" + y + ".png";
          },
          zIndex: 10,
        });
        layerT = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + "/" + x + "/" + y + ".png";
          },
          zIndex: 10,
        });
      }
      this.map_2 = new AMap.Map("previous", {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 20],
        center: centerPoint,
        layers: [layerT],
      });
      // 修改主题样式
      //   if (this.$$online) {
      //     this.map_2.setMapStyle('amap://styles/whitesmoke');
      //   }
      this.map_2.add(this.layer2);
      this.map_2.setFeatures(["bg", "road", "building"]);
    },
    init_map_2() {
      let layerT = [];
      let centerPoint = this.$Center;

      if (this.$online) {
        this.layer = new AMap.TileLayer.Satellite();
        layerT = new AMap.TileLayer();
      } else {
        this.layer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeSatelliteMap() + z + "/" + x + "/" + y + ".png";
          },
          zIndex: 10,
        });
        layerT = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + "/" + x + "/" + y + ".png";
          },
          zIndex: 10,
        });
      }
      this.map_2_upToDate = new AMap.Map("upToDate", {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 20],
        center: centerPoint,
        layers: [layerT],
      });
      // 修改主题样式
      //   if (this.$$online) {
      //     this.map_2_upToDate.setMapStyle('amap://styles/whitesmoke');
      //   }
      //添加比例尺
      this.map_2_upToDate.add(this.layer);
      this.map_2_upToDate.setFeatures(["bg", "road", "building"]);

      this.mouseTool = new AMap.MouseTool(this.map_2_upToDate); // 注册鼠标绘制工具
      // this.ruleTool = new AMap.MouseTool(this.map_2_upToDate); // 注册鼠标测量工具

      this.mouseTool.on("draw", function (obj) {
        console.log(obj.obj);
        this.drawed = true;
      });
      this.getMarksDetail();
      // this.ruleTool.on('draw', function () {
      //   this.ruled = true;
      // });
    },
    //同步操作
    init_map_3() {
      // 引入瓦片图-平面

      let _this = this;
      this.map_2.on("dragstart", () => {
        this.myMove = "left";
      });
      this.map_2_upToDate.on("dragstart", () => {
        this.myMove = "right";
      });
      //拖拽
      this.map_2.on("mapmove", () => {
        if (this.myMove == "left") {
          let zoom = _this.map_2.getZoom();
          let location = _this.map_2.getCenter();
          _this.map_2_upToDate.setZoomAndCenter(zoom, [
            location.lng,
            location.lat,
          ]); //同时设置地图层级与中心点
        }
      });
      this.map_2_upToDate.on("mapmove", () => {
        if (this.myMove == "right") {
          let zoom = _this.map_2_upToDate.getZoom();
          let location = _this.map_2_upToDate.getCenter();
          _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
        }
      });
      //放大缩小
      //   this.map_2.on('zoomstart', () => {
      //     this.myMove = 'left';
      //   });
      //   this.map_2_upToDate.on('zoomstart', () => {
      //     this.myMove = 'right';
      //   });
      this.map_2.on("zoomchange", () => {
        this.myMove = "left";
        if (this.myMove == "left") {
          let zoom = _this.map_2.getZoom();
          let location = _this.map_2.getCenter();
          _this.map_2_upToDate.setZoomAndCenter(zoom, [
            location.lng,
            location.lat,
          ]); //同时设置地图层级与中心点
        }
      });
      this.map_2_upToDate.on("zoomchange", () => {
        this.myMove = "right";
        if (this.myMove == "right") {
          let zoom = _this.map_2_upToDate.getZoom();
          let location = _this.map_2_upToDate.getCenter();
          _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
        }
      });
    },

    toLoadMapImg(row) {
      console.log("row", row);
      // 拿到数据
      this.mapname = row.mapname;
      //   this.chartingName = row.address;
      //   this.date = row.shooting_date;
      //   this.correlationData.img = row.path;
      let location = JSON.parse(row.angle_data);
      this.center = [
        (Number(location.leftdown.lng) + Number(location.rightup.lng)) / 2,
        (Number(location.leftdown.lat) + Number(location.rightup.lat)) / 2,
      ];
      //   let lng =
      //     (Number(location.leftdown.lng) + Number(location.rightup.lng)) / 2;
      //   let lat =
      //     (Number(location.leftdown.lat) + Number(location.rightup.lat)) / 2;
      //   this.correlationData.lng = lng;
      //   this.correlationData.lat = lat;
      //   this.Distance = this.GetDistance(
      //     location.leftdown.lat,
      //     location.rightup.lng,
      //     location.rightup.lat,
      //     location.rightup.lng
      //   );
      //在地图加载图片
      //   this.map_2.clearMap();
      //   this.map_2_upToDate.clearMap();
      if (location) {
        let imageLayer_2 = new AMap.ImageLayer({
          bounds: new AMap.Bounds(
            [location.leftdown.lng, location.leftdown.lat],
            [location.rightup.lng, location.rightup.lat]
          ),
          url: row.path, // 图片 Url
          zooms: [3, 20], // 设置可见级别，[最小级别，最大级别]
          zIndex: 12,
        });

        // 将图层添加至地图实例
        this.map_2_upToDate.add(imageLayer_2);
        this.map_2.setCenter(this.center);
        this.map_2_upToDate.setCenter(this.center);
        // this.map_2.setCenter([lng, lat]);
      }
    },
    // 计算经纬度距离
    // GetDistance(lat1, lng1, lat2, lng2) {
    //   var radLat1 = (lat1 * Math.PI) / 180.0;
    //   var radLat2 = (lat2 * Math.PI) / 180.0;
    //   var a = radLat1 - radLat2;
    //   var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
    //   var s =
    //     2 *
    //     Math.asin(
    //       Math.sqrt(
    //         Math.pow(Math.sin(a / 2), 2) +
    //           Math.cos(radLat1) *
    //             Math.cos(radLat2) *
    //             Math.pow(Math.sin(b / 2), 2)
    //       )
    //     );
    //   s = s * 6378.137; // EARTH_RADIUS;
    //   s = Math.round(s * 10000) / 10;
    //   return s;
    // },

    // 获取标注
    getMarksDetail() {
      this.$post(Api.mapMarkDetail(), {
        map_id: this.id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data) {
            console.log("获取标注", res.data);
            this.markID = res.data ? res.data.id : "";
            console.log(JSON.parse(res.data.spot));
            if (JSON.parse(res.data.spot) == null) {
              this.overlays.spot = [];
            } else {
              this.overlays.spot = JSON.parse(res.data.spot);
            }
            if (JSON.parse(res.data.line) == null) {
              this.overlays.line = [];
            } else {
              this.overlays.line = JSON.parse(res.data.line);
            }
            if (JSON.parse(res.data.surface) == null) {
              this.overlays.surface = [];
            } else {
              this.overlays.surface = JSON.parse(res.data.surface);
            }
            if (JSON.parse(res.data.icon) == null) {
              this.overlays.icon = [];
            } else {
              this.overlays.icon = JSON.parse(res.data.icon);
            }
            this.initMark();
          }
        }
      });
    },
    /**
     * 初始化标注
     */
    initMark() {
      let _this = this;
      // 消防员
      this.overlays.spot.forEach((ele) => {
        console.log("消防员", ele);
        let spot = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          // icon: new AMap.Icon({
          //   size: new AMap.Size(30, 36), // 图标尺寸
          //   image: require('@/assets/img/Marker/fireman.png'), // Icon的图像
          //   imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          // }),
          content: `<div style='width:16px;height:16px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
          // offset: new AMap.Pixel(-15, -36),
          offset: new AMap.Pixel(-8, -8),
          extData: { type: 1, dateID: ele.i, name: ele.name },
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span>`,
            direction: "bottom",
          },
        });

        spot.dateID = spot.on("click", this.showInfoM);
        this.map_2_upToDate.add(spot);
      });
      // 消防员
      this.overlays.icon.forEach((ele) => {
        let icon = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: ele.img, // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          // content: `<div style='width:9px;height:9px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
          // offset: new AMap.Pixel(-15, -36),
          // offset: new AMap.Pixel(0, 0),
          extData: { type: 4, dateID: ele.i, name: ele.name },
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span>`,
            direction: "bottom",
          },
        });

        icon.dateID = icon.on("click", this.showInfoM);
        this.map_2_upToDate.add(icon);
      });
      // 行动箭头
      this.overlays.line.forEach((ele) => {
        let path = [];
        ele.p.forEach((element) => {
          path.push(
            _this.$online ? [element.R, element.Q] : [element.O, element.P]
          );
        });
        let line = new AMap.Polyline({
          path: path,
          strokeColor: ele.color,
          strokeWeight: 6,
          lineJoin: "round",
          lineCap: "round",
          showDir: true,
        });
        let A = new AMap.Marker({
          position: path[0],
          content: " ",
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span><span style=';color:#fff'>总长` +
              line.getLength() +
              `米</span>`,
            direction: "bottom",
          },
        });
        line.setExtData({ type: 2, dateID: ele.i, measure: A, name: ele.name });
        line.on("click", this.showInfoM);
        this.map_2_upToDate.add(line);
        this.map_2_upToDate.add(A);
      });
      // 灾区
      this.overlays.surface.forEach((ele) => {
        let dA = [];
        ele.p.forEach((e) => {
          dA.push(_this.$online ? [e.R, e.Q] : [e.O, e.P]);
        });
        let surface = new AMap.Polygon({
          path: dA,
          fillColor: ele.color,
          strokeColor: ele.color,
          strokeWeight: 2,
          fillOpacity: 0.4,
        });
        let B = new AMap.Marker({
          position: dA[0],
          content: " ",
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span><span style=';color:#fff'>面积` +
              surface.getArea() +
              `平方米</span>`,
            direction: "bottom",
          },
        });
        surface.setExtData({
          type: 3,
          dateID: ele.i,
          measure: B,
          name: ele.name,
        });
        surface.on("click", this.showInfoM);
        this.map_2_upToDate.add(surface);
        this.map_2_upToDate.add(B);
      });
    },
    /**
     * 绘制
     */
    draw(type) {
      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning("请先完成当前标注");
          return;
        }
        if (this.tagType != 0) {
          this.$Message.warning("请先保存当前标注");
          return;
        }

        this.current = type;
        // this.ruleTool.close();
        let that = this;

        switch (type) {
          case 1: {
            // 消防员
            that.mouseTool.marker({
              // icon: new AMap.Icon({
              //   size: new AMap.Size(30, 36), // 图标尺寸
              //   image: require('@/assets/img/Marker/fireman.png'), // Icon的图像
              //   imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              // }),
              content: `<div style='width:16px;height:16px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
              offset: new AMap.Pixel(-8, -8),
              extData: { type: 1 },
            });
            break;
          }

          case 2: {
            this.drawed = false;
            //行动箭头
            that.mouseTool.polyline({
              strokeColor: that.mycolor.xcolor,
              strokeWeight: 6, //线宽
              lineJoin: "round",
              lineCap: "round",
              showDir: true,
              extData: { type: 2 },
            });
            break;
          }
          case 3: {
            this.drawed = false;
            //灾区
            that.mouseTool.polygon({
              fillColor: that.mycolor.mcolor,
              strokeColor: that.mycolor.mcolor,
              extData: { type: 3 },
            });
            break;
          }
          case 4: {
            // 消防员
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require("@/assets/img/Marker/fireman.png"), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-18, -36),
              extData: { type: 4 },
            });
            break;
          }
        }
      } else {
        this.$Message.info("请先启用标注功能");
      }
    },

    getDraw(e) {
      this.nowObj = e.obj;
      e.obj.on("click", this.showInfoM); // 监听覆盖物点击事件
      //   let Ce = this.$online ? e.obj.De : e.obj.F;

      if (e.obj.getExtData().type == 2) {
        // 重绘线段
        this.map_2_upToDate.remove(e.obj);
        let newAction = new AMap.Polyline({
          path: e.obj.getPath(),
          strokeColor: this.mycolor.xcolor,
          strokeWeight: 6,
          lineJoin: "round",
          lineCap: "round",
          showDir: true,
          extData: { type: 2 },
        });
        this.map_2_upToDate.add(newAction);
        this.nowObj = newAction;
        newAction.on("click", this.showInfoM);
      }

      this.tagType = this.nowObj.getExtData().type;
      this.drawed = true;
      this.mouseTool.close();
    },

    // 启用标注
    onMark() {
      // 测量完成前不可点击
      // if (!this.ruled) {
      //   this.$Message.warning('请先结束当前测量');
      //   return;
      // }
      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning("请先完成当前标注");
          return;
        }
        if (this.tagType != 0) {
          this.$Message.warning("请先保存当前标注");
          return;
        }
        this.current = 0;
        this.drawing = false;
        // this.del = false;
        //   this.$Message.info('已关闭标注功能');
        // this.map_2_upToDate.off("click", this.clickHandler);
        this.mouseTool.close();
      } else {
        this.$Message.info("选择标注物类型");
        // this.del = false;
        this.drawing = true;
        //监听draw事件可获取画好的覆盖物
        this.mouseTool.on("draw", this.getDraw);
      }
    },
    // 取消标注
    // offMark() {
    //   if (!this.ruled) {
    //     this.$Message.warning('请先结束当前测量');
    //     return;
    //   }

    // },

    // 删除标注
    // clearMark() {
    //   // if (!this.ruled) {
    //   //   this.$Message.warning('请先结束当前测量');
    //   //   return false;
    //   // }
    //   if (!this.drawed) {
    //     this.$Message.warning('请先完成当前标注');
    //     return false;
    //   }
    //   this.current = 0;
    //   this.mouseTool.close();
    //   this.drawing = false;
    //   this.del = !this.del;
    // },
    // 选中标注
    showInfoM(e) {
      if (!this.drawed) {
        return;
      }
      if (this.tagType != 0) {
        this.$Message.warning("请先保存当前标注");
        return;
      }
      this.drawing = true;
      this.nowObj = e.target;
      this.tagType = e.target.getExtData().type;
      this.tagName = e.target.getExtData().name;
      this.tagId = e.target.getExtData().dateID;
      switch (e.target.getExtData().type) {
        case 1:
          break;
        case 2:
          this.mycolor.xcolor = e.target.getExtData().color;
          break;
        case 3:
          this.mycolor.mcolor = e.target.getExtData().color;
          break;
        case 4:
          this.onImg = e.target.getExtData().img;
          break;
      }
    },
    //删除标注
    tagDelete(id) {
      this.map_2_upToDate.getAllOverlays().forEach((e) => {
        // console.log(e.getExtData().dateID);
        if (e.getExtData().dateID == id) {
          switch (e.getExtData().type) {
            case 1:
              this.overlays.spot.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.spot.splice(index, 1);
                }
              });
              break;
            case 2:
              this.overlays.line.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.line.splice(index, 1);
                }
              });
              break;
            case 3:
              this.overlays.surface.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.surface.splice(index, 1);
                }
              });
              break;
            case 4:
              this.overlays.icon.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.icon.splice(index, 1);
                }
              });
              break;
            case 4:
              this.overlays.icon.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.icon.splice(index, 1);
                }
              });
              break;
          }
          if (e.getExtData().type != 1) {
            this.map_2_upToDate.remove(e.getExtData().measure); // 移除覆盖物
          }
          if (e.getExtData().type != 1) {
            this.map_2_upToDate.remove(e.getExtData().measure); // 移除覆盖物
          }
          this.tagType = "";
          this.map_2_upToDate.remove(e); // 移除覆盖物
        }
      });
    },
    // 显示隐藏图形
    showTag(id, event) {
      this.map_2_upToDate.getAllOverlays().forEach((e) => {
        if (e.getExtData().dateID == id) {
          if (e.getVisible()) {
            e.hide();
            event.target.className = "ivu-icon ivu-icon-ios-eye-off";
          } else {
            e.show();
            event.target.className = "ivu-icon ivu-icon-ios-eye";
          }
        }
      });
    },
    // 修改名称
    testName(name) {
      this.$post(Api.updateOrthophoto(), {
        ortho_id: parseInt(this.id),
        team_id: this.myMap.team_id,
        map_name: name,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$Message.success(res.msg_customer);
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },

    // 保存
    save() {
      if (!this.drawed) {
        this.$Message.warning("请先完成当前标注");
        return;
      }
      if (this.tagType != 0) {
        this.$Message.warning("请先保存当前标注");
        return;
      }
      this.$post(Api.mapMarkAdd(), {
        map_id: this.id,
        spot: JSON.stringify(this.overlays.spot),
        line: JSON.stringify(this.overlays.line),
        surface: JSON.stringify(this.overlays.surface),
        icon: JSON.stringify(this.overlays.icon),
        mark_id: this.markID,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$Message.success(res.msg_customer);
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // rightClick() {
    //   console.log('点击了右键');
    // },
    headleChangeColor(e) {
      console.log("切换颜色");
      if (e == 1) {
        this.nowObj.setOptions({
          fillColor: this.mycolor.mcolor,
          strokeColor: this.mycolor.mcolor,
        });
      } else if (e == 2) {
        this.nowObj.setOptions({
          strokeColor: this.mycolor.xcolor,
        });
      }
    },
    //选择图标
    cusimg(e) {
      this.onImg = e;
      this.nowObj.setIcon(
        new AMap.Icon({
          size: new AMap.Size(30, 36), // 图标尺寸
          image: e, // Icon的图像
          imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
        })
      );
    },
    //保存标注
    saveDraw() {
      //   let Ce = this.$online ? this.nowObj.De : this.nowObj.F;
      let date = new Date().getTime();
      let that = this;
      if (this.tagName == "") {
        this.$Message.warning("请输入标注信息");
        return;
      }
      switch (that.nowObj.getExtData().type) {
        case 1:
          this.overlays.spot.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.spot.splice(index, 1);
            }
          });
          break;
        case 2:
          this.overlays.line.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.line.splice(index, 1);
            }
          });
          break;
        case 3:
          this.overlays.surface.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.surface.splice(index, 1);
            }
          });
          break;
        case 4:
          this.overlays.icon.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.icon.splice(index, 1);
            }
          });
          break;
      }
      switch (this.nowObj.getExtData().type) {
        case 1:
          that.nowObj.setLabel({
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              that.tagName +
              `</span>`,
            direction: "bottom",
          });
          that.nowObj.setExtData({ type: 1, dateID: date, name: that.tagName });
          that.overlays.spot.push({
            p: that.nowObj.getPosition(),
            i: date,
            name: that.tagName,
          });
          break;
        case 2:
          let A = new AMap.Marker({
            position: that.nowObj.getPath()[0],
            content: " ",
            label: {
              content:
                `<span style='text-align:center;display:block;font-weight: bold;font-size:16px;'>` +
                that.tagName +
                `</span><span style=';color:#fff'>总长` +
                that.nowObj.getLength() +
                `米</span>`,
              direction: "bottom",
            },
          });
          that.map_2_upToDate.add(A);
          that.nowObj.setExtData({
            type: 2,
            dateID: date,
            name: that.tagName,
            color: that.mycolor.xcolor,
            measure: A,
          });
          that.overlays.line.push({
            p: that.nowObj.getPath(),
            i: date,
            name: that.tagName,
            color: that.mycolor.xcolor,
          });
          break;
        case 3:
          let B = new AMap.Marker({
            position: that.nowObj.getPath()[0],
            content: " ",
            label: {
              content:
                `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
                that.tagName +
                `</span><span style=';color:#fff'>面积` +
                that.nowObj.getArea() +
                `平方米</span>`,
              direction: "bottom",
            },
          });
          that.map_2_upToDate.add(B);
          that.nowObj.setExtData({
            type: 3,
            dateID: date,
            name: that.tagName,
            color: that.mycolor.mcolor,
            measure: A,
          });
          that.overlays.surface.push({
            p: that.nowObj.getPath(),
            i: date,
            name: that.tagName,
            color: that.mycolor.mcolor,
          });
          break;
        case 4:
          that.nowObj.setLabel({
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              that.tagName +
              `</span>`,
            direction: "bottom",
          });
          that.nowObj.setExtData({
            type: 4,
            dateID: date,
            name: that.tagName,
            img: that.onImg,
          });
          that.overlays.icon.push({
            p: that.nowObj.getPosition(),
            i: date,
            name: that.tagName,
            img: that.onImg,
          });
          break;
        default:
          break;
      }

      // console.log(this.overlays);
      this.tagName = "";
      this.current = 0;
      this.tagType = 0;
    },
    //取消保存
    cancelDraw() {
      if (!this.nowObj.getExtData().dateID) {
        this.map_2_upToDate.remove(this.nowObj);
      }
      this.tagName = "";
      this.current = 0;
      this.tagType = 0;
      this.tagId = "";
    },
    // 放大
    zoomIn(e) {
      if (e == 1) {
        this.map_2.zoomIn();
      } else if (e == 2) {
        this.map_2_upToDate.zoomIn();
      }
    },
    // 缩小
    zoomOut(e) {
      if (e == 1) {
        this.map_2.zoomOut();
      } else if (e == 2) {
        this.map_2_upToDate.zoomOut();
      }
    },
    // 测量
    // rule(type) {
    //   // 绘图完成前不可测量
    //   if (!this.drawed) {
    //     this.$Message.warning('请先完成当前标注');
    //     return;
    //   }

    //   // this.offMark();
    //   this.ruled = false;
    //   let then = this;
    //   switch (type) {
    //     case 'rule': {
    //       then.ruleTool.rule({
    //         startMarkerOptions: {
    //           //可缺省
    //           icon: new AMap.Icon({
    //             size: new AMap.Size(19, 31), //图标大小
    //             imageSize: new AMap.Size(19, 31),
    //             image: 'https://webapi.amap.com/theme/v1.3/markers/b/start.png',
    //           }),
    //         },
    //         endMarkerOptions: {
    //           //可缺省
    //           icon: new AMap.Icon({
    //             size: new AMap.Size(19, 31), //图标大小
    //             imageSize: new AMap.Size(19, 31),
    //             image: 'https://webapi.amap.com/theme/v1.3/markers/b/end.png',
    //           }),
    //           offset: new AMap.Pixel(-9, -31),
    //         },
    //         midMarkerOptions: {
    //           //可缺省
    //           icon: new AMap.Icon({
    //             size: new AMap.Size(19, 31), //图标大小
    //             imageSize: new AMap.Size(19, 31),
    //             image: 'https://webapi.amap.com/theme/v1.3/markers/b/mid.png',
    //           }),
    //           offset: new AMap.Pixel(-9, -31),
    //         },
    //         lineOptions: {
    //           //可缺省
    //           strokeStyle: 'solid',
    //           strokeColor: '#FF33FF',
    //           strokeOpacity: 1,
    //           strokeWeight: 2,
    //         },
    //         //同 RangingTool 的 自定义 设置，缺省为默认样式
    //       });
    //       break;
    //     }
    //     case 'measureArea': {
    //       then.ruleTool.measureArea({
    //         strokeColor: '#80d8ff',
    //         fillColor: '#80d8ff',
    //         fillOpacity: 0.3,
    //         //同 Polygon 的 Option 设置
    //       });
    //       break;
    //     }
    //   }
    //   this.ruled=false
    // },
    // // 清除测量
    // clearRule() {
    //   this.ruleTool.close(true);
    // },

    /**
     * 保存网页为图片
     */
    // saveCanvas() {
    //   html2canvas(document.body, {
    //     useCORS: true,
    //     foreignObjectRendering: true,
    //     allowTaint: false,
    //   }).then(function (canvas) {
    //     // document.body.appendChild(canvas);
    //     // let imgUri = canvas.toDataURL("image/png");
    //     // // .replace("image/png", "image/octet-stream");
    //     // console.log(imgUri);
    //     // $("#download").attr("href", imgUri);
    //     // document.getElementById("download").click();
    //   });
    // },

    goBack() {
      this.$router.back(-1);
    },
    backCenter() {
      //   let centerPoint = this.$Center;
      this.map_2_upToDate.setCenter(this.center);
    },
    copy() {
      //   console.log(params.row.apk_url);
      let oInput = document.createElement("input");
      oInput.value = this.shareUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.style.display = "none";
      this.$Message.success("已复制到剪切板");
      document.body.removeChild(oInput);
    },

    // 开启/关闭分屏
    separateChange() {
      // if (!this.ruled) {
      //   this.$Message.warning('请先结束当前测量');
      //   return;
      // }
      if (!this.drawed) {
        this.$Message.warning("请先完成当前标注");
        return;
      }
      if (this.tagType != 0) {
        this.$Message.warning("请先保存当前标注");
        return;
      }
      if (this.mapType == "separate") {
        this.mapType = "";
      } else if (this.mapType == "roller") {
        document.getElementsByClassName("roller-content")[0].style.width =
          "50%";
        this.mapType = "separate";
      } else {
        this.mapType = "separate";
      }
    },
    // 开启/关闭卷帘
    rollerChange() {
      // if (!this.ruled) {
      //   this.$Message.warning('请先结束当前测量');
      //   return;
      // }
      if (!this.drawed) {
        this.$Message.warning("请先完成当前标注");
        return;
      }
      if (this.tagType != 0) {
        this.$Message.warning("请先保存当前标注");
        return;
      }
      if (this.mapType == "roller") {
        var left = document.getElementsByClassName("roller-content")[0];
        // resize.style.
        left.style.width = "50%";
        this.mapType = "";
      } else {
        this.mapType = "roller";
      }
    },
    moveStart(e) {
      var resize = document.getElementsByClassName("roller")[0];
      //   console.log(resize[0]);
      resize.setCapture && resize.setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
      var left = document.getElementsByClassName("roller-content")[0];
      //   var mid = document.getElementsByClassName('mid');
      var box = document.getElementsByClassName("Content")[0];
      //   console.log(e);
      var startX = e.clientX;
      resize.left = resize.offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = function (el) {
        var endX = el.clientX;
        console.log(endX);
        var moveLen = resize.left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        var maxT = box.clientWidth - resize.offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        if (moveLen < 20) moveLen = 20; // 左边区域的最小宽度为150px
        if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

        // resize.style.left = moveLen; // 设置左侧区域的宽度

        left.style.width = moveLen + "px";
        //   mid.style.width = box.clientWidth - moveLen - 10 + 'px';
      };
      document.onmouseup = function (evt) {
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
      };
    },

    dragControllerDiv() {
      var resize = document.getElementsByClassName("roller");
      var left = document.getElementsByClassName("roller-content");
      //   var mid = document.getElementsByClassName('mid');
      var box = document.getElementsByClassName("Content");
      console.log(resize);
      // 鼠标按下事件
      resize.onmousedown = function (e) {
        console.log(e);
        var startX = e.clientX;
        resize.left = resize.offsetLeft;
        // 鼠标拖动事件
        document.onmousemove = function (e) {
          let endX = e.clientX;
          let moveLen = resize.left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
          let maxT = box.clientWidth - resize.offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

          if (moveLen < 150) moveLen = 150; // 左边区域的最小宽度为150px
          if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

          resize.style.left = moveLen; // 设置左侧区域的宽度

          left.style.width = moveLen + "px";
          //   mid.style.width = box.clientWidth - moveLen - 10 + 'px';
        };
      };
      // 鼠标松开事件
      document.onmouseup = function (evt) {
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
      };
      resize.setCapture && resize.setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
      return false;
    },
  },
};
</script> 

<style  scoped lang="scss">
.content-box {
  color: $white;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .top {
    width: 100%;
    height: 50px;
    background-color: $xf_hui1;
    border-bottom: 1px solid $xf_hei2;
    border-left: 1px solid $xf_hei2;
    position: absolute;
    z-index: 200;
    div {
      width: 50px;
      height: 50px;
      text-align: center;
      // border-radius: 50%;
      // background: #fff;

      font-size: 30px;
      cursor: pointer;
      line-height: 45px;
    }
    .leftdiv {
      position: absolute;
      left: 0;
    }
  }
  .Content {
    width: 100%;
    position: relative;
    height: 100%;
    .inputTop {
      background-color: $xf_hui1;
      position: absolute;
      top: 50px;
      width: 100%;
      height: 47px;
      z-index: 100;
      text-align: center;
      input {
        width: 100%;
        margin: 8px 21px;
        background-color: $xf_hei3;
        color: $white;
        text-align: center;
        border: none;
        line-height: 30px;
      }
    }
    .inputBottom {
      position: absolute;
      bottom: 0;
      padding: 0.1rem;
      width: 100%;
      input {
        width: 3.6rem;
        margin: 0 0.1rem;

        padding: 0 10px;
        color: $white;
        border: none;
        line-height: 30px;
      }
    }
    .left {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      height: calc(100vh - 120px);

      //   height: 8.3rem;
    }
    .right {
      width: 100%;
      height: calc(100vh - 120px);

      //   height: 8.3rem;
    }
    .roller-content {
      width: 50%;
      overflow: hidden;
      .roller-box {
        position: absolute;
        top: 0;
        z-index: 3;
        // 百分比宽度换成rem
        // width: 100%;
        width: 18.2rem;
        height: calc(100vh - 120px);
        // height: 8.3rem;
      }
      .roller {
        background: #777;
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
        cursor: w-resize;
        padding-top: 4.3rem;
        div {
          width: 4px;
          height: 4px;
          background-color: #fff;
          border-radius: 2px;
          margin: 0 auto 4px;
        }
      }
    }
    .separate-content {
      width: 49.5%;
      overflow: hidden;
      .roller-box {
        position: absolute;
        top: 0;
        //   left: 20px;
        z-index: 3;
        width: 9.1rem;
        height: 8.87rem;
      }
    }
    .roller-ground {
      position: absolute;
      top: 0;
      //   left: 20px;
      z-index: 3;
      width: 100%;
    }
    .separate-ground {
      position: absolute;
      top: 0;
      right: 0px;
      z-index: 3;
      width: 49.5%;
    }
    .myicon {
      img {
        cursor: pointer;
        width: 36px;
        display: inline;
        margin: 0 6px;
        vertical-align: middle;
      }
    }
    .tagging {
      position: absolute;
      right: 0;
      top: 50px;
      width: 228px;
      height: 100%;
      font-size: 14px;
      z-index: 250;
      background-color: $xf_hei1;
      // border: 1px solid #ccc;
      // border-radius: 10px 0 0 10px;

      // text-align: center;
      .bzd {
        line-height: 20px;
        span {
          display: inline-block;
          width: 48px;
          text-align: center;
        }
      }
      .tagdiv {
        width: 100%;
        height: 6rem;
        overflow: auto;
      }
      .tagdel {
        background: $xf_hui1;
        border-top: solid 1px $xf_hui2;
        height: 55px;
        .center {
          float: left;
          width: 60%;
          height: 50px;
          div {
            line-height: 22px;
          }
          div:first-child {
            font-weight: bold;
          }
        }
      }
      p {
        font-weight: bold;
        font-size: 16px;
        border-bottom: 2px solid #aaa;
      }
      li {
        padding: 5px 15px;
        list-style: none;
        // border-bottom: 1px solid #ccc;
        vertical-align: middle;
        line-height: 40px;
        // width: 33%;
        // float: left;
        // text-align: center;
        img {
          cursor: pointer;
          width: 36px;
          display: inline;
          margin: 0 6px;
          vertical-align: middle;
        }
      }
    }

    .taggingMore {
      position: absolute;
      right: 228px;
      top: 50px;
      width: 228px;
      height: 100%;
      font-size: 14px;
      z-index: 250;
      background-color: $xf_hui1;
      // border: 1px solid #ccc;
      // border-radius: 10px 0 0 10px;
      li {
        padding: 11px 15px;
      }
      li:not(:first-child) {
        border-top: 2px solid $xf_hei2;
      }
      .color {
        display: inline-block;
        padding: 10px;
        div {
          top: 2px;
        }
      }
    }
    .boxtop {
      position: absolute;
      width: 100%;
      top: 98px;
      z-index: 10;
      background: rgba(0, 0, 0, 0.4);
      span {
        display: inline-block;
        width: 47%;
      }
      span:first-child {
        text-align: right;
      }
    }
    .box {
      width: 100%;
      height: 100%;
      //   margin-bottom: 20px;
      position: relative;
      .map,
      .img {
        width: 100%;
        height: 100%;
        background-color: #e6e6e6;
      }
      h2 {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #3860ee;
        text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, 2px 2px 0px #fff,
          -2px -2px 0px #fff, 3px 3px 0px #fff, -3px -3px 0px #fff;
      }
      h3 {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #ff021a;
        text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, 2px 2px 0px #fff,
          -2px -2px 0px #fff, 3px 3px 0px #fff, -3px -3px 0px #fff;
      }
      img {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .amap-scale-text {
    color: #fff;
  }
  /deep/ .amap-marker-content {
    color: #000;
  }
  .active {
    background-color: #abdcff;
    color: #fff;
  }
}
.active2 {
  background-color: $xf_hui2 !important;
}
.shareUrl {
  .share-span {
    display: inline-block;
    border: 1px solid $xf_hui7;
    padding: 5px;
    background-color: $xf_hui6;
    margin-right: 12px;
  }
  margin-bottom: 20px;
}
.qr {
  position: fixed;
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  z-index: 1800;
  background-color: rgba(0, 0, 0, 0.7);
  img {
    margin: 200px auto 0;
  }
  i {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
.mapControl {
  width: 50px;
  max-height: 400px;
  position: absolute;
  bottom: 20px;
  right: 0;

  .item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
    opacity: 0.8;
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;

    img {
      width: 20px;
      position: static !important;
    }

    .measureBox {
      position: absolute;
      top: 0;
      left: -120px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        padding: 3px 10px;
        background-color: #333;
        // opacity: 0.6;
        border-radius: 2px;
        margin-right: 10px;
        span {
          font-size: 16px;
          color: #fff;
        }
      }

      //   :nth-child(1) {
      //     opacity: 1;
      //   }
    }

    .hierarchyBox {
      z-index: 400 !important;
      border-radius: 5px;
      position: absolute;
      bottom: 0;
      // left: -90px;
      right: 40px;
      background-color: #333;
      padding: 10px;
      > div {
        width: 110px;
        margin-bottom: 6px;

        input {
          float: left;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        span {
          float: right;
          font-size: 12px;
          color: #fff;
          line-height: 16px;
        }
      }

      hr {
        margin: 15px 0;
      }
    }
  }

  //   .item:hover {
  //     opacity: 1;
  //   }
}
</style>